//@ts-nocheck
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { useGetClientMutation } from '../../api/billingUserApi'
import { useGetSitesInfoByClientMutation } from '../../api/userApi'
import iInCircle, { ReactComponent as IInCircle } from '../../assets/icons/iInCircle.svg'
import { Container } from '../../assets/styles/global'
import { selectUserBilling } from '../../store/userBillingSlice'
import { NotificationListClients } from '../settings/style'

export const AnalyticsBI = () => {
  const [getSitesInfoByClient, { data: sitesInfoByClient }] = useGetSitesInfoByClientMutation()
  const [getClient, { data: clientData }] = useGetClientMutation()

  const { billingSelectedDealerId } = useSelector(selectUserBilling)

  const [siteInfo, setSiteInfo] = useState({})

  useEffect(() => {
    ;(async () => {
      if (billingSelectedDealerId) {
        getSitesInfoByClient(billingSelectedDealerId)
        getClient(billingSelectedDealerId)
      }
    })()
  }, [billingSelectedDealerId])

  useEffect(() => {
    if (sitesInfoByClient?.data?.length) {
      setSiteInfo(sitesInfoByClient?.data[0])
    } else {
      setSiteInfo([])
    }
  }, [sitesInfoByClient])

  return (
    <Container style={{ padding: '0', marginTop: '-20px' }}>
      {siteInfo?.id && siteInfo?.counter_id && siteInfo?.udp_bi_analytics_verification_code ? (
        <iframe
          frameborder="0"
          sandbox="allow-popups allow-scripts allow-forms allow-same-origin allow-top-navigation-by-user-activation"
          title="Ваш сайт"
          width={'100%'}
          src={
            clientData?.bi_analytics_chart
              ? `https://datalens.yandex/${clientData.bi_analytics_chart}?counterID=${siteInfo?.counter_id}&hash=${siteInfo?.udp_bi_analytics_verification_code}`
              : `https://datalens.yandex/ce2oflsq3cnez?counterID=${siteInfo?.counter_id}&hash=${siteInfo?.udp_bi_analytics_verification_code}`
          }
          style={{
            width: '100%',
            border: 'none',
            height: '2500px'
          }}
        />
      ) : (
        <NotificationListClients style={{ maxWidth: 'fit-content', marginLeft: 'auto', marginRight: 'auto' }}>
          <IInCircle />
          Модуль BI аналитики не активен. Для подключения обратитесь к вашему аккаунт менеджеру.
        </NotificationListClients>
      )}
    </Container>
  )
}
